.NavBlock {
  display: grid;
  place-items: center;
  gap: 1rem;
  min-width: 270px;
  min-height: 165px;
  background-color: var(--neutral-color-500);
}

.NavBlock:hover {
  background-color: var(--neutral-color-400);
}

.NavBlock p {
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  color: var(--text-color);
  margin-bottom: 0;
}

.NavBlock .errorMessage {
  color: red;
  font-size: smaller;
}

.NavIcon {
  width: 60px;
  height: 60px;

  @media screen and (max-width: 990px) {
    width: 50px;
    height: 50px;
  }
}

.NavIcon.invert {
  filter: invert(1);
}
