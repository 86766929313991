.button {
  border: none;
  outline: none;
  cursor: pointer;

  background-color: var(--primary-color-500);
  padding: var(--space-sm) var(--space-md);
  border-radius: var(--radius-sm);
  color: var(--text-color);

  font-weight: bold;
  font-size: var(--font-md);
  display: grid;
  place-content: center;
}

.loader {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
