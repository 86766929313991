:root {
  --header-height: 69px;
  --breadcrumbs-height: 60px;
  --footer-height: 60px;
}


.app {
  padding-top: var(--header-height);
  padding-bottom: calc(var(--footer-height) + 2rem);
}


.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  padding-top: 1rem;
}

.content {
  max-width: var(--content-width);
  min-width: 380px;
  margin-inline: auto;
}

.content {
  width: 99%;
  /* Default width */
  margin: 0 auto;
  /* Centering the container */
  /* Optional padding */
}

/* Media query for tablets */
@media (min-width: 600px) {
  .content {
    width: 80%;
    padding: 0px;
  }
}

/* Media query for small desktops */
@media (min-width: 768px) {
  :root {
    --footer-height: 40px;
  }

  .content {
    width: 75%;
  }
}

/* Media query for large desktops */
@media (min-width: 992px) {
  .content {
    width: 70%;
  }
}

/* Media query for extra large desktops */
@media (min-width: 1200px) {
  .content {
    width: 60%;
  }
}