.header {
  display: flex;
  border-bottom: 1px solid #e5e5e5;
  width: 100%;
  padding-inline: 1rem;
  background-color: var(--neutral-color-700);
  justify-content: space-between;
  align-items: center;

  position: fixed;
  top: 0;
  height: var(--header-height);
}

.right {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--space-md);
  color: var(--neutral-color-100);
}

.link {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  color: var(--neutral-color-100);
  transition: color 0.3s ease;
  border-radius: var(--radius-sm);
  font-size: 2rem;
}

.link span {
  font-size: 1.35rem;
}

.sidebar {
  display: flex;
  flex-direction: column;
  background: var(--neutral-color-900);
  position: fixed;
  top: 0;
  height: 100%;
  --_sidebar-width: min(80vw, 350px);
  z-index: 1;
  width: var(--_sidebar-width);
  right: calc(var(--_sidebar-width) * -1);
  transition: right 0.4s cubic-bezier(0, 1, 0, 1);
}

.sidebarOpen .sidebar {
  right: 0;
}

.sidebarHeader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-inline: 1rem;
  height: var(--header-height);
  background-color: var(--neutral-color-700);
  color: var(--neutral-color-100);
  border-bottom: 1px solid #e5e5e5;
}

.sidebarContent {
  padding-block: var(--space-lg);
  padding-inline: var(--space-md);
  display: flex;
  flex-direction: column;
  gap: var(--space-xl);
}

.sidebarCloseButton {
  color: var(--neutral-color-100);
  font-size: 2rem;
}

.backdrop {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.75);
  transition: right 0.4s cubic-bezier(0, 1, 0, 1);
  opacity: 0;
}

.sidebarOpen .backdrop {
  display: block;
  opacity: 1;
}

.link:hover {
  background-color: transparent;
}

/* tablet and up */
@media screen and (min-width: 768px) {
  .right {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: var(--neutral-color-100);
  }

  .link {
    color: var(--neutral-color-100);
    transition: color 0.3s ease;
    padding: 0.5rem;
    display: grid;
    place-items: center;
    border-radius: var(--radius-sm);
  }

  .link span {
    display: none;
  }

  .link:hover {
    background-color: var(--neutral-color-800);
  }

  .sidebar {
    display: contents;
  }

  .sidebarCloseButton,
  .sidebarHeader {
    display: none;
  }

  .sidebarContent {
    display: flex;
    gap: 1rem;
    flex-direction: row;
    padding: 0;
  }
}