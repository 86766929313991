:root {
  --max-width: 1100px;
  --border-radius: 12px;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 61, 96, 70;
  --background-end-rgb: 61, 96, 70;
  --neutral-color-50: #f5f5f5;
  --neutral-color-100: #c9caca;
  --neutral-color-200: #9e9e9e;
  --neutral-color-300: #727373;
  --neutral-color-400: #474747;
  --neutral-color-500: #2b2b2b;
  --neutral-color-600: #232323;
  --neutral-color-700: #202020;
  --neutral-color-800: #1d1d1d;
  --neutral-color-900: #101010;

  --primary-color-50: #d1e0f0;
  --primary-color-100: #b3cce6;
  --primary-color-200: #94b8db;
  --primary-color-300: #75a3d1;
  --primary-color-400: #578fc7;
  --primary-color-500: #3d7ab8;
  --primary-color-600: #29527a;
  --primary-color-700: #1f3d5c;
  --primary-color-800: #14293d;
  --primary-color-900: #0a141f;

  --danger-color: #ffb8b9;

  --radius-xs: 2px;
  --radius-sm: 6px;
  --radius-md: 8px;
  --radius-lg: 12px;
  --radius-xl: 16px;

  font-size: 18px;

  --content-width: 1200px;

  --elevation-1: 0px 2px 4px rgba(0, 0, 0, 0.2);
  --elevation-2: 0px 4px 8px rgba(0, 0, 0, 0.25);
  --elevation-3: 0px 8px 16px rgba(0, 0, 0, 0.3);
  --elevation-4: 0px 12px 24px rgba(0, 0, 0, 0.35);

  --space-xs: 0.25rem;
  --space-sm: 0.5rem;
  --space-md: 1rem;
  --space-lg: 1.25rem;
  --space-xl: 1.5rem;

  --font-xs: 0.75rem;
  --font-sm: 0.875rem;
  --font-md: 1rem;
  --font-lg: 1.125rem;
  --font-xl: 1.25rem;

  --background-color: var(--neutral-color-500);

  --primary-glow: conic-gradient(from 180deg at 50% 50%,
      #16abff33 0deg,
      #0885ff33 55deg,
      #54d6ff33 120deg,
      #0071ff33 160deg,
      transparent 360deg);
  --secondary-glow: radial-gradient(rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0));

  --base-color: var(--neutral-color-500);

  --base-color-soft: var(--neutral-color-300);

  --text-color: var(--neutral-color-50);
  --text-color-soft: var(--neutral-color-100);

  /* --link-color: var(--primary-color-200); */
  --link-color: var(--neutral-color-100);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Futura";
}

html,
body {
  max-width: 100vw;
  min-height: 100dvh;
  overflow-x: hidden;
}

body {
  color: var(--neutral-color-500);
  background: linear-gradient(to bottom, transparent, var(--neutral-color-700)) var(--neutral-color-800);
  font-family: "Roboto", sans-serif;
}

section {
  margin-bottom: 2rem;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: var(--text-color, white);
}

h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--text-color, white);
  font-weight: bold;
  text-transform: uppercase;
}

h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--text-color, white);
  font-weight: bold;
  text-transform: uppercase;
  font-style: italic;
}

p {
  font-size: 1rem;
  color: var(--text-color, white);
  margin-bottom: 1rem;
}

a {
  color: inherit;
  text-decoration: none;
}

.grid-2 {
  display: grid;
  gap: 1rem;
}

/* tablet and up */
@media (min-width: 768px) {
  .grid-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }

}


/* add font from public */
@font-face {
  font-family: "Futura";
  src: url("../public/fonts/FuturaLtBT/FUTURAL.TTF");
  font-weight: 400;
  font-style: normal;
}