.text {
  color: var(--text-color);
  line-height: 1.4rem;
}

.contentItem {
  margin-bottom: 1.5rem;
  overflow-x: auto;
  font-size: 19px;
}

.list {
  padding-left: 2rem;
  margin-bottom: 1rem;
}

.listItem {
  color: var(--text-color);
  margin-bottom: 0.4rem;
}

.autolink {
  color: var(--link-color);
  text-decoration: underline;
  transition: color 0.3s ease;
}

.table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.table div {
  overflow: visible;
}

.table p {
  margin: 0;
}

.table.tableWithHeader tr:first-child {
  background-color: var(--neutral-color-900);
  text-align: center;
  font-weight: bold;
  height: 1rem;
}

.table.tableWithRowNumbers td:first-child {
  background-color: var(--neutral-color-900);
  width: 4rem;
  min-width: 3rem;
  max-width: 4rem;
  text-align: center;
  font-weight: bold;
}

.table td {
  padding: 1rem;
  border: 1px solid var(--neutral-color-50);
  text-align: center;
}

.upload {
  max-width: min(500px, 100%);
}

/* Tablet and Smaller Desktop */
@media (min-width: 700px) {}

/* desktop */
@media (min-width: 1120px) {}

@media (prefers-color-scheme: dark) {}