.breadcrumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: 100%;
  gap: 0.1rem;

  color: var(--text-color);
  font-size: 1.3rem;

  max-width: var(--content-width);
  margin-inline: auto;

  margin-bottom: var(--space-lg);
}

.breadcrumbs a {
  display: flex;
  align-items: center;
  text-wrap: nowrap;
}

.breadcrumbs a:hover {
  text-decoration: underline;
}