.input {
  outline: none;
  width: 100%;
  height: auto;
  padding: var(--space-sm) var(--space-md);
  margin: 0;
  border: 1px solid var(--neutral-color-500);
  box-sizing: border-box;
  background-color: var(--neutral-color-400);
  color: var(--text-color);
  border-radius: var(--radius-sm);
  font-size: var(--font-md);
}

.input:focus {
  outline: none;
  border: 1px solid var(--neutral-color-50);
}

.input::placeholder {
  color: var(--text-color-soft);
  font-size: var(--font-md);
}