.errorMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 4rem;
}

.link {
  color: var(--text-color);
}